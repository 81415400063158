

















































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin, Validation } from 'vuelidate';
import { addMinutes } from 'date-fns';

import { API_BOOKING_OFFSET_TIME } from '@/env';
import { formatDateString } from '@/date';
import { unescapeHtml } from '@/string';
import { displayWarn, displayError, displaySuccess, displayWait } from '@/helpers/message';
import TenantHelper from '@/helpers/tenant';
import FacilityHelper from '@/helpers/facility';
import EventHelper, { bookingEventOnCustomFacilityError } from '@/helpers/event';
import { UiFacility } from '@/models/facility';
import VueButton from '@/components/atoms/Button.vue';

import ChairIcon from '@/assets/images/chair-icon.svg';
import SuccessIcon from '@/assets/images/icon-success.svg';
import logger from '@/logger';

@Component({
  components: { VueButton, ChairIcon, SuccessIcon },
})
export default class BookingFacilityModal extends mixins<Validation>(validationMixin) {
  isLoading = true;

  endTime = addMinutes(new Date(), API_BOOKING_OFFSET_TIME / 1000 / 60);

  @Prop({ type: Object }) facility!: UiFacility;

  @Prop({ type: Number }) durationInMinutes!: number;

  get tenantHelper(): TenantHelper {
    return new TenantHelper(this);
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  get formattedEndTime(): string {
    return formatDateString(this.endTime.toString(), 'HH:mm');
  }

  mounted(): void {
    this.updateEndTime();
    setInterval(this.updateEndTime, 1000);
  }

  updateEndTime(): void {
    const now = new Date();
    this.endTime = addMinutes(now, this.durationInMinutes + API_BOOKING_OFFSET_TIME / 1000 / 60);
  }

  async submitAction(): Promise<void> {
    const tenantId = this.tenantHelper.currentTenantId();
    if (!(await this.tenantHelper.validTenantId(tenantId))) {
      displayError(
        unescapeHtml(
          this.$gettextInterpolate(this.$gettext('Failed to book %{facilityName}'), {
            facilityName: this.facilityHelper.facilityName(this.facility),
          }),
        ),
      );
      return;
    }

    try {
      displayWait(this.$gettext('Processing...'));
      const updatedFacility = await this.eventHelper.createFacilityEvent(
        tenantId,
        this.facility,
        this.durationInMinutes,
      );
      displaySuccess(
        unescapeHtml(
          this.$gettextInterpolate(this.$gettext('%{facilityName} has been booked'), {
            facilityName: this.facilityHelper.facilityName(this.facility),
          }),
        ),
      );

      this.$emit('submit', 'book', updatedFacility);
    } catch (e) {
      if (e.message === bookingEventOnCustomFacilityError) {
        displayWarn(
          this.$gettext("We are sorry, facility reservation is not supported for custom facilities"),
        );
        logger.warn("Attempted to book an event on a custom facility", { ...this.facility });
      } else {
        logger.error('Failed to book facility', this.facilityHelper.facilityName, e);
        displayError(
          unescapeHtml(
            this.$gettextInterpolate(this.$gettext('Failed to book %{facilityName}'), {
              facilityName: this.facilityHelper.facilityName(this.facility),
            }),
          ),
        );
      }
    }
  }

  hideAction(): void {
    this.$emit('hide');
  }
}
