import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import ArrowTriangle from '@/assets/images/arrow-triangle.svg';

export type SimpleSelectorItem = string | object;

@Component({ components: { ArrowTriangle } })
export default class SimpleSelector extends Vue {
  @Prop({ type: Array }) items!: SimpleSelectorItem[];

  @Prop({ type: Number, default: 0 }) index!: number;

  @Prop({ type: String, default: '' }) defaultName!: string;

  get currentItem(): SimpleSelectorItem {
    return !!this.items && this.index < this.items.length ? this.items[this.index] : this.defaultName;
  }

  selectPrevious(): void {
    if (!this.isDisabledPreviousSelector) {
      this.selectIndex(Math.max(0, this.index - 1));
    }
  }

  selectNext(): void {
    if (!this.isDisabledNextSelector) {
      this.selectIndex(Math.min(this.items.length - 1, this.index + 1));
    }
  }

  get moreThanOneItem(): boolean {
    return this.items.length > 1;
  }

  get isDisabledPreviousSelector(): boolean {
    return this.index === 0;
  }

  get isDisabledNextSelector(): boolean {
    return this.index === this.items.length - 1;
  }

  selectIndex(newIndex: number): void {
    if (this.index !== newIndex) {   
      this.$emit('select-item', this.items[newIndex]);
    }
  }
}
