/* eslint-disable import/prefer-default-export */

// See: https://github.com/Polyconseil/vue-gettext/blob/84975abf647012c6ccc5fe15fb2191ff8c3526d0/src/interpolate.js#L47-L53
export const unescapeHtmlMap = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&quot;': '"',
  '&#039;': "'",
  '&#39;': "'",
} as { [key: string]: string };

export const unescapeHtmlKeys = Object.keys(unescapeHtmlMap);

export const escapeHtmlMap = Object.keys(unescapeHtmlMap).reduce(
  (acc, key) => {
    const value = unescapeHtmlMap[key];
    acc[value] = key;
    return acc;
  },
  {} as { [key: string]: string },
);

export const escapeHtmlKeys = Object.keys(escapeHtmlMap);

/**
 * @name escapeHtml
 *
 * @desc Escape HTML
 * @param {String} unescapedHtml the unescpaed HTML
 *
 * @return {String} returns the escaped HTML
 */
export function escapeHtml(unescapedHtml: string): string {
  const regExp = new RegExp(`[${escapeHtmlKeys.join()}]`, 'g');
  return unescapedHtml.replace(regExp, (m: string): string => {
    return escapeHtmlMap[m] || m;
  });
}

/**
 * @name unescapeHtml
 *
 * @desc Unescape HTML
 * @param {String} escapedHtml the escpaed HTML
 *
 * @return {String} returns the unescaped HTML
 */
export function unescapeHtml(escapedHtml: string): string {
  // lodash/unescape doesn't support '&#039;' contained zero.
  // To do address the problem, unescapeHtml is implemented.

  const regExp = new RegExp(`(?:${unescapeHtmlKeys.join(')|(?:')})`, 'g');
  return escapedHtml.replace(regExp, (m: string): string => {
    return unescapeHtmlMap[m] || m;
  });
}
