import Vue from 'vue';
import Component from 'vue-class-component';

import { Prop } from 'vue-property-decorator';

import kebabCase from 'lodash/kebabCase';
import ChairIcon from '@/assets/images/chair-icon.svg';
import FacilityHelper from '@/helpers/facility';
import EventHelper from '@/helpers/event';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import { isToday } from '@/date';

import CancelIcon from '@/assets/images/icon-cancel.svg';

@Component({
  components: {
    ChairIcon,
    CancelIcon,
  },
})
export default class FacilityBookingRow extends Vue {
  currentDate: Date = new Date();

  isPopupOpen = false;

  isMinBlockedCheckList = [15, 30, 60];

  @Prop({ type: Object, required: true }) facility!: UiFacility;

  @Prop({ type: String, required: true }) actionType!: 'book' | 'cancel';

  created(): void {
    // TODO: Move getting date to parent component because of wasteful calculation
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000 * 60);
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  isMinBlocked(minutes: number): boolean {
    return this.eventHelper.nextEventInMinutes(this.facility, this.currentDate) < minutes;
  }

  statusText(facility: UiFacility): string {
    switch (facility.status_name) {
      case FacilityStatusNameEnum.Occupied:
        return `${this.$gettext('Booked')} / ${this.facilityHelper.organizer(facility)}`;
      case FacilityStatusNameEnum.NoAttendees:
        return `${this.$gettext('Not in Use')} / ${this.facilityHelper.organizer(facility)}`;
      case FacilityStatusNameEnum.Vacant:
        return this.$gettext('Available');
      case FacilityStatusNameEnum.NoReservation:
        return this.$gettext('Unbooked Usage');
      default:
        return this.$gettext('Unknown Status');
    }
  }

  capacityText(facility: UiFacility): string {
    switch (facility.status_name) {
      case FacilityStatusNameEnum.Occupied:
        return `${this.facilityHelper.attendeesCount(facility)} / ${this.facilityHelper.capacity(
          facility,
        )}`;
      case FacilityStatusNameEnum.NoAttendees:
        return `${this.facilityHelper.attendeesCount(facility)} / ${this.facilityHelper.capacity(
          facility,
        )}`;
      case FacilityStatusNameEnum.Vacant:
        return this.facilityHelper.capacity(facility);
      case FacilityStatusNameEnum.NoReservation:
        return `? / ${this.facilityHelper.capacity(facility)}`;
      default:
        return `- / ${this.facilityHelper.capacity(facility)}`;
    }
  }

  isToday(dateString: string): boolean {
    return isToday(dateString);
  }

  kebabCase(s: string): string {
    return kebabCase(s);
  }

  setPopupOpenStatus(status: boolean): void {
    this.isPopupOpen = status;
  }

  submitAction(durationInMinutes?: number): void {
    this.setPopupOpenStatus(false);
    this.$emit('submit', this.facility, this.actionType, durationInMinutes);
  }
}
