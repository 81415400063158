































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { differenceInMinutes } from 'date-fns';
import { State, namespace } from 'vuex-class';
import { SettingsQuery , TenantDetails } from '@/store/types/modules';

import { API_PERIODICAL_LISTING_DURATION, API_CACHE_MINUTES } from '@/env';
import { UiFacility, FacilityUrlQuery, FacilityStatusNameEnum } from '@/models/facility';
import {
  splitFacilitiesByBooking,
  splitFacilitiesByMyBooking,
  sortByFacilityName,
  prioritizeByFacilityStatus,
  mergeFacilitiesWithEventUpdatedTime,
  removeEndedFacilityEvent,
} from '@/formatters/facility';
import Loading from '@/components/atoms/Loading.vue';
import FacilityBookingRow from '@/components/molecules/FacilityBookingRow.vue';
import FacilityBookingList from '@/components/organisms/FacilityBookingList.vue';
import SimpleSelector from '@/components/molecules/SimpleSelector.vue';
import BookingFacilityModal from '@/components/organisms/BookingFacilityModal.vue';
import { displayError, displaySuccess, displayWait, displayInfo } from '@/helpers/message';
import FacilityHelper from '@/helpers/facility';
import EventHelper from '@/helpers/event';
import logger from '@/logger';

const INITIAL_DURATION = 15;

const view = namespace('view');

@Component({
  components: {
    Loading,
    FacilityBookingList,
    FacilityBookingRow,
    BookingFacilityModal,
    SimpleSelector,
  },
})
export default class BookingFacility extends Vue {
  onTenantFirstLoad = true;

  isLoading = true;

  isConfirmationShown = false;

  facility = {} as UiFacility;

  bookingDurationInMinutes = INITIAL_DURATION;

  allFacilities = [] as UiFacility[];

  allBookableFacilities = [] as UiFacility[];

  bookedFacilities = [] as UiFacility[];

  occupiedFacilities = [] as UiFacility[];

  vacantFacilities = [] as UiFacility[];

  @Prop({ type: [String, Array] }) building!: string | string[];

  @State('tenant') tenant!: TenantDetails;

  @view.State('settingsQuery') viewSettingsQuery!: SettingsQuery;

  @view.Mutation('setBookingFacility') viewSetBookingFacility!: (bookingFacility: Pick<FacilityUrlQuery, 'building'>) => void;

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get eventHelper(): EventHelper {
    return new EventHelper(this);
  }

  get currentTenantId(): string {
    return this.tenant.current.id;
  }

  get selectableBuildings(): string[] {
    return this.facilityHelper.buildings(this.allBookableFacilities) || [];
  }

  get currentBuilding(): string {
    if (this.selectableBuildings.length > 0) {
      const buildings = this.building ? this.building : this.viewSettingsQuery?.bookingFacility?.building;
      const building = Array.isArray(buildings) ? buildings[0] : buildings;
      if (building && this.selectableBuildings.includes(building)) {
        return building;
      }
      return this.selectableBuildings[0];
    }
    return '';
  }

  get currentBuildingIndex(): number {
    return this.selectableBuildings.indexOf(this.currentBuilding);
  }

  isBookedRecently(): boolean {
    return (
      this.bookedFacilities.filter(bF => {
        if (!bF.event || !bF.event.updated) {
          return false;
        }
        const now = new Date();
        const eventDate = new Date(bF.event.updated);
        return differenceInMinutes(now, eventDate) <= API_CACHE_MINUTES;
      }).length > 0
    );
  }

  async mounted(): Promise<void> {
    // Loadding effect appeares at first time
    this.isLoading = true;
    await this.mount();
    this.isLoading = false;

    setInterval(async () => {
      if (!this.isBookedRecently()) {
        await this.mount();
      }
    }, API_PERIODICAL_LISTING_DURATION);
  }

  async mount(): Promise<void> {
    try {
      this.allFacilities = await this.facilityHelper.fetchFacilities(this.currentTenantId, {}, 'name');
    } catch(e) {
      logger.error('Failed to get facility information', e);
      displayError(this.$gettext('Failed to get facility information.'));
    }
    if (this.allFacilities.length < 1) {
      return;
    }
    const oldSelectableBuildingsLength = this.selectableBuildings.length;
    this.allBookableFacilities = [...this.allFacilities.filter(facility => !!facility.calendar_integration_id)];
    this.selectBuilding(this.currentBuilding);
    // The message is relevant only when there are only custom buildings, so shouldn't show where there's simply no buildings
    // Display the message only when selectableBuildings.length has dropped to zero or is zero initially when the tenant's data is first loaded
    const firstLoadAndNoSelectableBuildings = this.onTenantFirstLoad && !this.selectableBuildings.length;
    const selectableBuildingsBecameNothing = !this.onTenantFirstLoad && oldSelectableBuildingsLength && !this.selectableBuildings.length;
    if (this.allFacilities.length && (firstLoadAndNoSelectableBuildings || selectableBuildingsBecameNothing)) {
      displayInfo(this.$gettext("Booking facility is not available because all of your facilities are not integrated with any calendars"),{ isFlashed: false });
    }
    this.onTenantFirstLoad = false;
  }

  @Watch('currentTenantId')
  onCurrentTenantChange(): void {
    this.onTenantFirstLoad = true;
    this.mount();
  }

  splitAllBookableFacilities(
    allBookableFacilities: UiFacility[],
  ): {
      bookedFacilities: UiFacility[];
      vacantFacilities: UiFacility[];
      occupiedFacilities: UiFacility[];
    } {

    let [occupiedFacilities, vacantFacilities, bookedFacilities] = [
      [] as UiFacility[],
      [] as UiFacility[],
      [] as UiFacility[],
    ];

    const latestFacilities = mergeFacilitiesWithEventUpdatedTime(
      removeEndedFacilityEvent(allBookableFacilities),
      allBookableFacilities,
    );

    [occupiedFacilities, vacantFacilities] = splitFacilitiesByBooking(latestFacilities);

    [bookedFacilities, occupiedFacilities] = splitFacilitiesByMyBooking(
      occupiedFacilities,
      this.$store.state.user.accountEmails ? this.$store.state.user.accountEmails : [],
    );
    occupiedFacilities
      .sort(sortByFacilityName())
      .sort(prioritizeByFacilityStatus(FacilityStatusNameEnum.NoAttendees));
    vacantFacilities
      .sort(sortByFacilityName())
      .sort(prioritizeByFacilityStatus(FacilityStatusNameEnum.Vacant));
    bookedFacilities.sort(sortByFacilityName());

    return { bookedFacilities, vacantFacilities, occupiedFacilities };
  }

  filterToCurrentBuildingOnly(facilities: UiFacility[], currentBuildingName: string): UiFacility[] {
    return facilities.filter(bF => {
      return bF?.features?.building === currentBuildingName;
    });
  }

  belongToBuilding(facility: UiFacility, buildingName: string): boolean {
    return facility?.features?.building === buildingName;
  }

  selectBuilding(currentBuildingName: string): void {
    const { bookedFacilities, vacantFacilities, occupiedFacilities } = this.splitAllBookableFacilities(this.allBookableFacilities);

    const belongToCurrentBuilding = (facilty: UiFacility): boolean => this.belongToBuilding(facilty, currentBuildingName);
    this.bookedFacilities = bookedFacilities.filter(belongToCurrentBuilding);
    this.vacantFacilities = vacantFacilities.filter(belongToCurrentBuilding);
    this.occupiedFacilities = occupiedFacilities.filter(belongToCurrentBuilding);
  }

  handleSelectBuilding(currentBuildingName: string): void {
    this.selectBuilding(currentBuildingName);
    const { currentRoute } = this.$router;
    let nextBookingFacilityQuery = {};
    if (currentBuildingName) {
      nextBookingFacilityQuery = { building: [currentBuildingName] };
    }
    if (
      currentRoute.name === 'bookingFacility' &&
      JSON.stringify(currentRoute.query) === JSON.stringify(nextBookingFacilityQuery)
    ) {
      return;
    }

    this.viewSetBookingFacility(nextBookingFacilityQuery);
    this.$router.push({
      name: 'bookingFacility',
      query: nextBookingFacilityQuery,
    });
  }

  async handleSubmitAction(
    facility: UiFacility,
    actionType: 'book' | 'cancel',
    durationInMinutes?: number,
  ): Promise<void> {
    this.facility = { ...facility };

    if (actionType === 'book') {
      this.isConfirmationShown = true;
      this.bookingDurationInMinutes = durationInMinutes || INITIAL_DURATION;
      return;
    }

    try{
      displayWait(this.$gettext('Processing...'));
      const updatedFacility = await this.eventHelper.deleteFacilityEvent(this.currentTenantId, facility);
      this.bookedFacilities = this.bookedFacilities.filter(bookedFacility => {
        return bookedFacility.facility_id !== facility.facility_id;
      });
      displaySuccess(
        this.$gettextInterpolate(this.$gettext('%{facilityName} has been released'), {
          facilityName: this.facilityHelper.facilityName(facility),
        }),
      );

      this.submitAction(actionType, updatedFacility);
    } catch(e) {
      logger.error('Failed to release facility', facility, e);
      displayError(
        this.$gettextInterpolate(this.$gettext('Failed to release %{facilityName}'), {
          facilityName: this.facilityHelper.facilityName(facility),
        }),
      );
    }
  }

  async submitAction(actionType: 'book' | 'cancel', facility: UiFacility): Promise<void> {
    this.isConfirmationShown = false;
    this.facility = {} as UiFacility;

    const currentBuildingName = this.selectableBuildings[this.currentBuildingIndex];
    const { features } = facility;
    if (!features || !features.building) {
      return;
    }

    if (features.building !== currentBuildingName) {
      return;
    }

    if (actionType === 'book') {
      this.bookedFacilities = [...this.bookedFacilities, ...[facility]].sort(sortByFacilityName());

      this.vacantFacilities = this.vacantFacilities.filter(vF => {
        return vF.facility_id !== facility.facility_id;
      });
      return;
    }

    this.bookedFacilities = this.bookedFacilities.filter(bF => {
      return bF.facility_id !== facility.facility_id;
    });

    this.vacantFacilities = [...this.vacantFacilities, ...[facility]]
      .sort(sortByFacilityName())
      .sort(prioritizeByFacilityStatus(FacilityStatusNameEnum.Vacant));
  }

  hideAction(): void {
    this.isConfirmationShown = false;
  }

  isBookedFacilityBookingRow(facility: UiFacility): boolean {
    if (
      facility.status_name !== FacilityStatusNameEnum.Occupied &&
      facility.status_name !== FacilityStatusNameEnum.NoAttendees
    ) {
      return false;
    }

    return true;
  }

}
